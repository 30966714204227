@import (reference) '../../theme.less';

.home {
  .dots {
    li {
      margin-right: 10px;
    }

    button {
      width: 15px !important;
      height: 15px !important;
    }
  }
  .slick-dots {
    bottom: -35px;
  }
  .slick-dots li.slick-active button:before{
    color: #A68E6F;
    opacity: 1;
  }
  .slick-dots li:hover button:before{
    color: #A68E6F;
    opacity: 1;
  }
  .slick-dots li:active button:before{
    color: #A68E6F;
    opacity: 1;
  }
  .slick-dots li button:before {
    color: #A68E6F;
    font-size: 14px;
  }

  .table {
    td {
      font-weight: normal;
    }

    .productName {
      text-align: left;
      padding-left: 40px;
    }
  }

  .carousel {

    img {
      width: 100%;
    }
  }

  .hot-products {
    margin: 0 auto;
    margin-top: @margin-huge+10;
    margin-bottom: @margin-huge;
    width: @page-width;
  }

  .selected-products, .partners {
    .hot-products;
    margin-top: @margin-huge;

  }

  .partners {
    .link-box {
      display: flex;
      flex-wrap: wrap;

      .link-item {
        width: 25%;
        margin-top: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

    }
  }


  .title {
    margin-bottom: @margin-huge - 60;
  }

  .product-item {
    width: 360px;
    margin-left: 20px;
    margin-right: 20px;
    border: 1px solid @border-color-base;
    box-shadow: 0px 0px 8px 4px rgba(209, 209, 209, 1);
    border-radius: 8px;

    &.active {
      transform: scale(1.1);

      .pro-title {
        background: @primary-color-dark;
      }
    }

    .pro-title {
      height: 120px;
      background: @primary-color;
      padding: 30px 35px;
      transition: all 0.6s linear;
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        margin-left: 15px;
        color: @white-color;
        font-size: @font-large;
        font-weight: 500;
        position: relative;
      }
    }

    .content {
      padding: 30px 35px 60px 35px;
      height: 380px;
      text-align: center;
      .profit-rate {
        font-size: @font-huge - 2;
        color: #EDB35B;
        text-align: center;
        font-weight: 600;
      }

      .profit-type {
        color: @text-color-secondary;
        text-align: center;
        font-size: @font-middle - 2;
        margin-top: @margin-sm;
        margin-bottom: @margin-sm;
      }

      .invest-wrap {
        display: flex;
        justify-content: space-between;
        margin-top: @margin-md+10;
        margin-bottom: @margin-lg + 30;

        .invest {
          & > span:first-child {
            color: @text-color-secondary;
            font-size: @font-middle  - 2;
            margin-right: @margin-sm;
          }

          & > span:last-child {
            color: @text-color;
            font-size: @font-middle - 2;
          }
        }
      }
    }
  }
}
