@import (reference) '../../../theme.less';
.my-kyc{
    background:@white-color;
    padding:30px 40px;
    .title{
        font-size:@font-large;
        font-weight: 600;
        margin-bottom: @margin-lg;
    }
    .type{
        margin-bottom: @margin-md;
        span{
            width:80px;
            padding:10px 20px;
            font-weight: 600;
            font-size: @font-base;
            cursor: pointer;
            &.active{
                color:@primary-color;
                border-bottom: 2px solid @primary-color;
            }
        }
    }

.operators{
    display: flex;
    justify-content: flex-end;
    .btn{
        width:90px;
        padding:0;
        margin-right: @margin-sm;
    }
}
.m-t{
    margin-top: @margin-lg;
}

}