@import (reference) '../../../theme.less';

.product-detail {
  background: @white-color;
  padding: 30px 60px;

  h1 {
    font-size: @font-large + 2;
    font-weight: 600;
    margin-bottom: @margin-lg;
    color: @text-color;
  }

  .ele {
    li {
      margin-bottom: @margin-md;
      font-size: @font-middle;

      .txt {
        text-align: right;
        color: @text-color-secondary;
        width: 130px;
        display: inline-block;
      }

      .val {
        color: @text-color;
        margin-left: 50px;


      }
    }
  }

  .t-t {
    color: @primary-color;
    font-weight: 600;
    font-size: @font-large;
    display: flex;
    justify-content: space-between;
    margin-bottom: @margin-md;
  }

  .table {
    & > thead > tr > th {
      background: #F7F8FC;
      font-size: @font-middle;
      font-weight: 400;
      color: @text-color-secondary;
    }

    & > tbody > tr > td {
      font-weight: 400;
      font-size: @font-middle;
      text-align: center;

      &.profit {
        color: #EDB35B;
      }

    }
  }

  .proof {
    margin-top: @margin-lg;

    & > span {
      color: @primary-color;
      font-size: @font-large;
      font-weight: 600;
      display: inline-block;
      margin-bottom: @margin-md;
    }

    & > ul > li {
      & > img {
        width: 28px;
        margin-right: @margin-md;
      }

      & > span {
        font-size: @font-middle;
      }
    }
  }
}
