/************
        全局公共样式，类似于common.css
            *****************/
@import (reference) './theme.less';

/******reset antd*********/
.ant-carousel .slick-dots-bottom {
  bottom: -30px;
}

.ant-carousel .slick-dots li {
  margin-right: @margin-lg;
}

.ant-carousel .slick-dots li button {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background: @text-color-secondary
}

.ant-carousel .slick-dots li.slick-active button {
  width: 30px;
  height: 30px;
  background: @primary-color;
}

body {
  color: @text-color;

  p {
    margin: 0
  }

  ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.table {
  width: 100%;
  border: none;

  thead {
    th {
      border: none;
      background: @primary-color;
      height: 60px;
      color: @white-color;
      font-size: @font-large - 2;
      text-align: center;
    }
  }

  tbody {
    tr {
      height: 60px;

      &.odd {
        background: #F7F8FC;
      }

      td {
        border: none;
        font-size: @font-large - 2;
        color: @text-color;
        font-weight: bold;

        .seemore {
          color: @text-color;

          &:hover {
            color: @primary-color-dark;
          }
        }

        &.baseline {
          color: #EDB35B;
          font-size: @font-large;
        }

      }
    }
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.pointer {
  cursor: pointer;
}

.mt-5 {
  margin-top: 5px;
}

.mt-20 {
  margin-top: 20px;
}
.mt-50{
  margin-top: 50px;
}

.mt-100 {
  margin-top: 100px;
}
.mb-100{
  margin-bottom: 100px;
}

.mb-200 {
  margin-bottom: 200px;
}

.ml-20 {
  margin-left: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.w100 {
  width: 100%;
}

.btn {
  width: 200px;
  padding: 10px 20px;
  border: 1px solid @primary-color;
  font-size: @font-large - 2;
  color: @primary-color;
  background: @white-color;
  border-radius: 10px;
  text-align: center;
  font-weight: 400;
  letter-spacing: 0.1em;
  cursor: pointer;

  &:hover {
    color: @white-color;
    background: @primary-color-dark;
  }
}

.bg-transparent {
  background: transparent;
}

.bg-white {
  background: white;
}
