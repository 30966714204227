@import (reference) '../../../theme.less';
@import (reference)  '../my-product/index.less';

.my-asset {
  .my-product();

  .top {
    height: 160px;
  }

  .pro-bottom .type li {
    font-size: @font-middle;
  }

  .status {
    color: @primary-color-dark;
    line-height: 68px;
  }

  .pro-bottom .type li {
    width: 90px;
  }

  .time {
    padding-left: 28px;
    margin-bottom: @margin-md;

    & > span {
      margin-right: @margin-md;
      font-size: @font-middle;
    }
  }

  .pagination {
    width: 65%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 48px 0;
    background: #fff;
    margin: 15px auto 51px;
  }
}
