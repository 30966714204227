@import (reference) '../../theme.less';

.company {
  .img-box {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  .img-swiper {
    margin: 10px;
    padding: 2%;
    position: relative;
    width: 300px;
    height: 200px;
  }

  .icon-left {
    width: 33px;
    height: 33px;
    position: absolute;
    z-index: 33;
    cursor: pointer;
    top: 80px;
    left: -20px;
    color: @primary-color
  }

  .icon-right {
    width: 33px;
    height: 33px;
    position: absolute;
    z-index: 33;
    cursor: pointer;
    right: -20px;
    top: 80px;
    color: @primary-color
  }

  .carousel {
    margin-top: 50px;
    position: relative;
  }

  .company-map {
    transform: scale(0.8);
    margin-top: -10px;
  }

  .text-desc {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding-left: 20px;
  }

  .slick-center {
    img {
      transform: scale(1.2);
    }
  }


  .news-type {
    display: flex;
    justify-content: space-around;

    .img-active {
      height: 137px;
    }

    img {
      height: 120px;
      width: 285px;
      cursor: pointer;
    }

    .active-plate {
      border: 1px solid @primary-color-dark;
      color: @primary-color
    }

  }

  .banner {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    .video {
      position: relative;
      width: @page-width;
    }

    .muted {
      position: absolute;
      right: 30px;
      bottom: 30px;
      cursor: pointer;

      img {
        width: 30px;
        height: 30px;
      }
    }
  }

  .anchor-box {
    //position: sticky;
    //position: -webkit-sticky;
    margin-top: 50px;
    margin-left: 50px;
    width: 170px;
    background: transparent;
    top: 120px;
  }

  .anchor {
    display: flex;
    width: 120px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .anchor-name {
    font-size: 20px;
    color: @primary-color
  }

  .right-arrow {
    font-size: 24px;
    color: @primary-color;
    font-weight: bold;
  }

  .stick {
    position: sticky;
    position: -webkit-sticky;
    top: 500px;
  }


  .company-trends {
    width: @page-width;
    margin: auto;
    margin-top: @margin-lg;
    margin-bottom: 70px;

    .title {
      margin-bottom: @margin-lg;
    }

    .pre-text {
      color: #333;
      line-height: 28px;
      font-size: 14px;
      margin-top: 5px;

      .bold {
        color: #333333;
        font-weight: bold;
      }
    }

    .culture {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
    }

    .block {
      width: 100%;
      font-weight: 400;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 20px;

      .block-title {
        color: @primary-color;
        font-size: 20px;
        font-weight: bold;
        text-align: right;
        width: 105px;
      }

      .block-desc {
        color: #8E8D8E;
        font-size: 16px;
        padding: 0 20px;
        text-align: left;
        font-weight: bold;
        width: 500px;
      }

      .text-center {
        text-align: center;
      }
    }
  }

  .core-bg {
    padding-top: 50px;
    height: 995px;
    background: url("../../images/bg.png");
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;

    .team-top {
      width: @page-width - 100;
      padding: 50px 0 100px 0;
      margin: 100px 0px 0 0px;
      background: rgba(255, 255, 255, 0.5);
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }

    .team-bottom {
      width: @page-width - 100;
      background: @white-bg;
      height: 300px;
      position: relative;

    }

    .person {
      cursor: pointer;

      .header {
        width: 100px;
        height: 100px;
      }

      .name {
        margin-top: 25px;
        color: @black-color;
        font-size: 15px;
        font-weight: bold;
      }

      .position {
        margin-top: 5px;
        color: @black-color;
        font-size: 14px;
        font-weight: bold;
      }
    }

    .huge-person {
      position: relative;
      top: -90px;

      .header {
        width: 140px;
        height: 140px;
      }

      .name {
        margin-top: 25px;
        color: @black-color;
        font-size: 16px;
        font-weight: bold;
      }

      .position {
        margin-top: 5px;
        color: @black-color;
        font-size: 14px;
        font-weight: bold;
      }

      .desc {
        color: #8E8D8E;
        line-height: 28px;
        font-size: 16px;
        text-align: left;
        margin: 15px 200px 0 200px;
      }
    }
  }
}