@import (reference) '../../../theme';

.productDetailAll {
  .container {
    width: 100%;
    background: #F2F4FF;
    padding-bottom: 100px;

    .more {
      padding: 20px 20px 0 20px;
    }

    .topBg {
      width: 100%;
      height: 430px;
      background-image: url("../../../images/productDetailBg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .header {
      width: 65%;
      //padding: 52px 72px 72px 37px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: -330px auto 0;
      background: #fff;

      .headerLeft {
        flex: 6;
        border-right: 1px solid #F8F8F8;
        padding-bottom: 82px;

        .titleBox {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 52px 0 41px 37px;
          border-bottom: 1px solid #F0F0F0;
          margin-bottom: 62px;

          .title {
            font-size: 20px;
            color: #545559;
            margin-right: 20px;
          }

          .desc {
            font-size: 14px;
            color: #EBB25A;
            padding: 3px 10px;
            border-radius: 5px;
            border: 1px solid #EBB25A;
            min-width: 120px;
            max-width: 150px;
            text-align: center;
            margin-right: 10px;
          }
        }

        .infoMain {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 77px 0 37px;

          .info {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            .val {
              font-size: 18px;
              color: #545559;
              margin-bottom: 32px;
              font-weight: 500;

              &.spec {
                color: #EBB25A;
              }
            }

            .key {
              font-size: 14px;
              color: #333;
            }
          }
        }

        .progress {
          margin-top: 86px;
          padding: 0 77px 0 37px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .key {
            color: #8D6F4A;
            font-size: 14px;
            margin-right: 20px;
            white-space: nowrap;
          }
        }
      }

      .headerRight {
        flex: 3;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 0 36px;

        .table {
          margin-bottom: 71px;
          text-align: center;

          .th {
            padding: 17px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #ededed;

            .thTxt {
              color: #8D6F4A;
              font-size: 18px;
            }
          }

          .td {
            border: 1px solid #F0F0F0;
            border-top: none;
            padding: 25px 20px 0;

            .tdVal {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 20px;

              .tdTxt {
                color: #474747;
                font-size: 14px;
              }
            }
          }
        }

        .contactBtn {
          padding: 10px 50px;
          background: #8D6F4A;
          border-radius: 8px;
          color: #fff;
          font-size: 16px;
          font-weight: 500;
          cursor: pointer;
        }
      }
    }

    .main {
      margin: 25px auto 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 65%;

      .mainLeft {
        flex: 6;
        padding: 36px 50px 50px 50px;
        background: #fff;

        .title {
          color: #474747;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 20px;
        }

        .desc {
          color: #EBB25A;
          font-size: 18px;
          margin-bottom: 20px;
        }

        .table {
          margin-bottom: 20px;

          .tr {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-right: 1px solid #F2F2F2;

            &:last-child {
              .td {
                border-bottom: 1px solid #F2F2F2;
              }
            }

            .th {
              flex: 2;
              background: #ededed;
              padding: 5px 10px;
              color: #000;
              font-size: 16px;
              text-align: center;
            }

            .td {
              flex: 5;
              padding: 5px 30px;
              border-top: 1px solid #F2F2F2;
              color: #474747;
              font-size: 14px;
              text-align: center;
            }
          }
        }

        .content {
          color: #6c6c6c;
          font-size: 14px;
          margin-bottom: 20px;
        }
      }

      .mainRight {
        flex: 4;
        margin-left: 30px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        .titleBox {
          padding: 15px 20px;
          border-bottom: 1px solid #F0F0F0;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .title {
            color: #474747;
            font-size: 18px;
          }
        }

        .icon {
          margin-right: 30px;
          width: 22px;
          height: 22px;
          cursor: pointer;
        }

        .fileList {
          padding: 20px 20px 0;

          .title {
            color: #8D6F4A;
            font-size: 16px;
            margin-bottom: 20px;
          }

          .fileName {
            color: #333;
            font-size: 14px;
            cursor: pointer;

            &:hover {
              color: @primary-color
            }
          }

        }
        .topFile {
          background: #fff;
          padding-bottom: 20px;
          width: 100%;
        }
        .bottomFile {
          width: 100%;
          margin-top: 15px;
          background: #fff;
          padding-bottom: 20px;
        }
      }
    }
  }
}
