.mt35 {
  margin-top: 35px;
}

.indialInfo {
  .container {
    padding: 43px 56px;
    background: #fff;

    .title {
      font-size: 24px;
      font-weight: 500;
      color: #474747;
    }

    .form {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 20px;
      padding-left: 40px;

      .formTitle {
        color: #474747;
        font-size: 15px;
        margin-right: 30px;
        width: 150px;
        text-align: right;
      }

      .formCom {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .desc {
          color: #ADADAE;
          font-size: 14px;
          margin-left: 20px;
          white-space: nowrap;
        }

        .imgDesc {
          color: #ADADAE;
          font-size: 14px;
          margin-left: 20px;
          width: 280px;
        }

        .inputStyle {
          margin-bottom: 0;
        }
      }
    }

    .submitBtn {
      width: 100%;
      text-align: center;
      margin-top: 50px;

      .submit {
        height:40px;
        background: #8D6F4A;
        padding: 0 75px;
        color: #fff;
        border-radius: 10px;
        text-align: center;
        cursor: pointer;
      }
    }

  }
}
