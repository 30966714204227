@import (reference) '../../theme.less';

.calendar {
  .ant-tooltip-inner {
    background: #ffffff !important;
    z-index: 3 !important;
    width: 300px !important;
    -moz-box-shadow: 2px 2px 5px #888888 !important; /* 老的 Firefox */
    box-shadow: 2px 2px 5px #888888 !important;
  }
}

.center {
  position: relative;
  min-height: 430px;
  padding-top: @margin-md;


  & > img {
    width: 100%;
    position: absolute;
    top: 0;
  }

  & > .wrap {
    position: relative;
    z-index: 10;
    min-height: 700px;
    display: flex;
    width: @page-width;
    margin: 0 auto;

    .left {
      width: 250px;
      background: #E9EDF4;
      padding-top: 30px;
      color: @text-color;
      font-size: @font-middle;
      font-weight: 600;
      position: relative;


      .sec-nav {
        padding: 0;

        li {
          width: 100%;
          height: 46px;
          padding-left: 40px;
          line-height: 46px;
          position: relative;
          letter-spacing: 0.1em;
          cursor: pointer;

          &.active {
            background: @white-color;
          }

          i {
            width: 8px;
            height: 16px;
            background-image: url('../../images/right-arrow.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            position: absolute;
            right: 40px;
            top: 14px;
          }
        }
      }

      .top {
        padding-left: 40px;
        padding-right: 30px;
        margin-bottom: @margin-md;

      }

      .tag {
        display: flex;
        justify-content: space-between;

        img {
          cursor: pointer;
        }

      }

      .advtar-wrap {
        display: flex;
        align-items: center;
        margin-top: @margin-md;
        margin-bottom: @margin-md;

        .name{
          margin-left: 10px;
        }
      }
    }

    .right {
      flex: 1;
      background: #F7F8FC;

    }
  }
}
