.ArticleDetail {
  background: #F2F4FF;
  padding-bottom: 70px;
  .navTitle {
    width: 60%;
    margin: 0 auto;
    padding: 20px 0;
    font-size: 14px;
    color: #727676;
    .navTitleSpe {
      color: #A68E6F;
    }
  }
  .container {
    width: 65%;
    margin: 0 auto;
    text-align: center;
    background: #fff;
    padding: 30px 25px 100px 25px;
    .header {
      border-bottom: 1px solid #f2f2f2;
      padding-bottom: 25px;
      margin-bottom: 25px;
      .time {
        display: inline-block;
        margin-right: 30px;
        font-size: 14px;
        color: #474747;
      }
      .source {
        font-size: 14px;
        color: #474747;
      }
    }
  }
}