@import (reference) '../../theme';

.eula-box {
  padding: 40px;
  .title {
    text-align: center;
    margin-bottom: 50px;

  }

  line-height: 2;

  .xinshi {
    color: #333333;
    font-weight: bold;
  }

  .agree {
    text-align: center;

  }

  .ft16 {
    font-size: 20px;
    color: #282828;
    margin: 10px 0;
  }

}
