@import (reference) './theme';

.App {
  .info-wrap {
    background: @primary-color;

    .info {
      height: 40px;
      width: @page-width;
      margin: 0 auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: @font-base - 2;
      color: @white-color;
      font-weight: 600;
      //letter-spacing: 0.01em;
    }
  }

  .hot-line {
    font-size: @font-base - 2px;
  }

  .nav {
    display: flex;
  }

  .tel {
    width: 20px;
    height: 18px;
    margin-right: @margin-sm;
  }


  .wechat {
    width: 30px;
    height: 26px;

  }

  .mobile-wrap {
    margin-right: @margin-sm;
    position: absolute;
    z-index: 999;
  }

  .wechat-wrap {
    position: absolute;
    z-index: 999;
  }

  .qrCode {
    position: absolute;
    top: 40px;
    left: 3px;
    width: 112px;
    height: 123px;
    z-index: 999;
    background: #fff;
    display: none;
  }

  .do(@p, @c) {
    .@{p} {
      width: 50px;
      height: 40px;
      line-height: 35px;
      text-align: center;
      position: relative;
      cursor: pointer;

      .@{c} {
        width: 24px;
        height: 20px;
        display: none;
      }

      .@{c}-white {
        width: 24px;
        height: 20px;
        display: inline-block;
      }

      .@{c}-fwh {
        width: 100px;
        height: 100px;
        transition: all 0.1s linear;
      }

      &:hover {
        background: @white-color;

        .@{c}-white {
          display: none;
        }

        .@{c} {
          display: inline-block;
        }

        .@{c}-fwh {
          height: 100px;
        }

        .qrCode {
          display: block;
          -moz-box-shadow: 2px 2px 5px #888888;
          /* 老的 Firefox */
          box-shadow: 2px 2px 5px #888888;
        }
      }
    }
  }

  @p: wechat-wrap;
  @c: wechat;
  @p1: mobile-wrap;
  @c1: mobile;
  .do(@p, @c);
  .do(@p1, @c1);

  .mobile-wrap {

    .mobile-white,
    .mobile {
      width: 15px;
    }
  }

  .user,
  .logout {
    cursor: pointer;
    font-size: 14px;
  }

  .nav-wrap {
    width: 100%;
    background: @white-color;
    height: 83px;
  }

  .nav {
    width: @page-width;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .xs-logo {
      height: 50px;
      width: 126px;
      margin-top: 20px;
    }

    .navigator {
      margin-left: 150px;
      height: 83px;
      line-height: 98px;
      flex: 1;
      justify-content: space-between;
      display: flex;
      align-items: flex-start;

      li {
        font-size: @font-large;
        font-weight: 400;
        color: @text-color;
        position: relative;
        cursor: pointer;

        &.active {
          color: @primary-color;

          &:after {
            content: '';
            display: inline-block;
            width: 100%;
            height: 4px;
            background: @primary-color-dark;
            position: absolute;
            bottom: 14px;
            left: 0;
          }
        }
      }
    }
  }

  .footer-wrap {
    background: @black-color;
    padding-bottom: 15px;
    .footer {
      width: @page-width;
      margin: 0 auto;
      display: flex;
      padding: 40px 0 0;
      color: @white-color;

      .left {}

      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      &>div {
        flex: 1;
      }

      h4 {
        font-size: @font-large - 2;
        color: @white-color;
        font-weight: 600;
        margin-bottom: @margin-sm;
      }

      a,
      p {
        color: @white-color;
        display: block;
        font-size: @font-middle - 2;
        margin-bottom: @margin-xs;

      }

      a {
        &:hover {
          color: @primary-color-dark;
        }
      }

      .time {
        font-size: @font-middle - 2;
      }

      .er-wrap {
        display: flex;
        justify-content: space-between;
        margin-top: @margin-lg;
        width: 400px;


        &>div {
          &>img {
            width: 100px;
            height: 100px;
          }

          &>span {
            display: block;
            margin-top: @margin-sm;
            text-align: center;
          }
        }

      }
    }
  }


}