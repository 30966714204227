.mt20 {
  margin-top: 20px;
}

.product {
  border: 1px solid #f2f4ff;
  width: 100%;
  height: 100%;
  background: #f2f4ff;

  .topBg {
    width: 100%;
    height: 430px;
    background-image: url("../../images/productBg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .title {
      font-size: 45px;
      font-weight: 600;
      color: #fff;
      border-bottom: 5px solid #fff;
      padding-bottom: 20px;
      letter-spacing: 7px;
    }
  }

  .filterCondition2 {
    height: 200px;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 65%;
    margin: -94px auto 20px;
    background: #fff;
    padding: 37px 0 0 20px;
    .titleContent{
      margin-top: 50px;
      font-size: 18px;
    }
  
  }

  .filterCondition {
    height: 320px;
    width: 65%;
    margin: -94px auto 20px;
    background: #fff;
    padding: 37px 0 0 20px;

    .tab {
      margin-bottom: 28px;
      display: flex;
      justify-content: flex-start;
      align-content: flex-end;

      .tabBtn {
        font-size: 16px;
        font-weight: 600;
        color: #545559;
        position: relative;
        margin-right: 70px;
        cursor: pointer;
        height: 42px;

        &.active {
          color: #A48C6E;

          &:after {
            display: block;
            content: '';
            width: 65px;
            height: 4px;
            background: #A48C6E;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
      }
    }

    .filterContainer {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .desc {
        font-size: 14px;
        font-weight: 600;
        color: #333;
        margin-right: 26px;
        white-space: nowrap;
        width: 70px;
        text-align: right;
      }

      .filterBtn {
        font-size: 14px;
        font-weight: 400;
        color: #545559;
        cursor: pointer;
        margin-right: 44px;

        &.active {
          color: #A48C6E;
        }
      }
    }
  }

  .filterCondition2 {
    height: 200px;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 65%;
    margin: -94px auto 20px;
    background: #fff;
    padding: 37px 0 0 20px;

    .titleContent {
      margin-top: 50px;
      font-size: 18px;
    }
  }

  .productList {
    padding: 44px 128px 47px 20px;
    background: #fff;
    margin: 15px auto 0;
    width: 65%;

    .titleBox {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 62px;

      .title {
        font-size: 16px;
        color: #545559;
        margin-right: 20px;
      }

      .desc {
        font-size: 14px;
        color: #EBB25A;
        padding: 1px 10px;
        border-radius: 5px;
        border: 1px solid #EBB25A;
      }
    }

    .infoMain {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .val {
          font-size: 18px;
          color: #EBB25A;
          margin-bottom: 32px;
          font-weight: 500;
        }

        .key {
          font-size: 14px;
          color: #333;
        }
      }

      .detailBtn {
        color: #8D6F4A;
        padding: 5px 43px;
        background: #fff;
        border-radius: 5px;
        border: 1px solid #8D6F4A;
        cursor: pointer;

        &:hover {
          background: #8D6F4A;
          color: #fff;
        }
      }
    }

  }

  .empty {
    background: #fff;
    width: 65%;
    margin: 15px auto 0;
    padding: 100px;
  }

  .pagination {
    width: 65%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 48px 0;
    background: #fff;
    margin: 15px auto 51px;
  }
}