@import (reference) '../../theme.less';

.classify-title {
  width: 100%;
  height: 126px;

  .frame {
    margin: 0 auto;
    width: 240px;
    height: 40px;
    border: 2px solid @primary-color-dark;
    position: relative;

    .cn {
      color: @primary-color-dark;
      position: absolute;
      left: 50%;
      top: 34px;
      transform: translate(-50%, -50%);
      font-weight: 600;
      font-size: @font-huge;
      background: @white-color;
      width: 70%;
      text-align: center;
      letter-spacing: 0.1em;
    }

    .peach {
      background: #F8F2F3;
    }

    .en {
      font-size: @font-huge;
      font-weight: 600;
      color: @primary-color-dark;
      position: absolute;
      left: 50%;
      top: 90px;
      transform: translate(-50%, -50%);
      width: 400px;
      text-align: center;
      letter-spacing: 0.04em;

    }

    .line {
      width: 80px;
      height: 3px;
      background: @primary-color-dark;
      position: absolute;
      left: 50%;
      top: 120px;
      margin-left: -40px;
    }
  }
}
