@import (reference) '../../theme.less';
.display-cards{
    width:100%;
    height:100%;
    overflow: hidden;
    transition:all 0.6s linear;
    padding-top: @margin-lg;
    .children-wrap{
        transition:all 0.6s linear;

        & > div{
            float:left;
            transition:all 0.6s linear;
        }
    }
}