@import (reference) '../../theme';

.login {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .statement {
    margin: 0 auto;
    width: @page-width;
    padding:100px;
  }

  .text-primary {
    color: @primary-color-light
  }

  .info-wrap {
    background: @white-color;

    .info {
      height: 80px;
      width: @page-width;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: @font-middle;
      color: @white-color;
      font-weight: 600;

      & > img {
        width: 126px;
        height: 50px;
      }

      & > div {
        display: flex;

        .vertical-line {
          margin: 0 @margin-md;
          color: @text-color-secondary;
        }

        span {
          font-size: @font-middle;
          color: @text-color;
        }
      }

      & .hot-line {
        display: flex;
        align-items: center;

        i {
          display: inline-block;
          width: 31px;
          height: 26px;
          background-image: url('../../images/tel.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
          margin-right: @margin-md;
        }

        & > span {
          color: @primary-color-dark;
        }

      }

      .tel {
        cursor: pointer;
      }
    }
  }

  .flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .flex1 {
    flex: 1;
  }

  .login-bg {
    width: 100%;
    flex: 1;
    background: url("../../images/login-bg.png") no-repeat center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    .code {
      padding: 11px 13px;
      background: #DEDEDE;
      color: #868686;
      margin-left: 15px;
      margin-bottom: 19px;
      cursor: pointer;
    }
  }


  .login-register {
    background: #ffffff;
    padding: 40px 30px 60px 30px;
    margin-right: 10%;
    min-width: 400px;

    .login-title {
      font-size: 20px;
      color: #474747;
      font-weight: 500;
      margin-bottom: 40px;
    }

    .login-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: @primary-color-light;
      margin-top: 5px;

      span {
        cursor: pointer;
      }
    }

    .form-item-input {
      height: 45px;
      margin-top: 5px;
    }

    .login-form-button {
      width: 100%;
      margin-top: 30px;
      height: 45px;
      background: @primary-color-dark;

      &:disabled {
        background: #eeeeee;
      }
    }

  }

  .footer {
    height: 110px;
    background: @white-color;
    padding-top: 40px;

    p {
      font-size: @font-large;
      color: @text-color-secondary;
      text-align: center;
    }
  }

  .login-area {
    width: 460px;
    height: 400px;
    background: @white-color;
    position: absolute;
    right: 15%;
    top: 15%;
    padding: 28px 40px;

    &.register-area {
      height: 460px;

      h2 {
        margin-bottom: @margin-md;
      }

      .login-form {
        input {
          margin-bottom: @margin-md;
        }
      }
    }

    h2 {
      font-weight: 600;
      color: @text-color;
      margin-bottom: @margin-lg;
    }

    .login-form {
      input {
        margin-bottom: @margin-md+10;
      }

      input.form-control {
        display: block;
        border: 1px solid @border-color-base;
        height: 50px;
        width: 100%;
        padding-left: 20px;
        font-size: @font-middle;
      }

      .other-style {
        display: flex;
        justify-content: space-between;
        color: #F1C481;
        font-weight: 600;
        margin-bottom: @margin-lg;

        span {
          cursor: pointer;
        }
      }

      .btn {
        width: 100%;
        border-radius: 6px;
        background: @primary-color-dark;
        color: @white-color;
        letter-spacing: 0.2em;

        &:hover {

        }
      }

      .valid-code {
        display: flex;
        justify-content: space-between;
      }

      .get-code {
        width: 200px;
        height: 50px;
        line-height: 50px;
        background: #DEDEDE;
        font-size: @font-middle;
        text-align: center;
        cursor: pointer;
      }

      .agree-wrap {
        margin-bottom: @margin-md;
        display: flex;
        justify-content: space-between;

        & > span {
          cursor: pointer;
          color: @primary-color-dark;
        }
      }

      .agreement {
        color: #EDB35B;
      }
    }
  }
}
