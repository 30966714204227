@import (reference) '../../../theme.less';

@keyframes jelly {
  0%,
  100% {
    transform: scale(1, 1);
  }

  25%,
  75% {
    transform: scale(0.9, 1.1);
  }

  50% {
    transform: scale(1.1, 0.9);
  }
}

.my-rpq {
  background: @white-color;
  padding: 30px 40px;

  & > h1 {
    text-align: center;
    font-size: @font-large + 4;
    font-weight: 600;
  }

  & > p {
    text-align: center;
    color: @text-color-secondary;
    font-size: @font-middle;
  }

  .btn-wrap {
    margin-top: @margin-lg;
    text-align: center;
  }

  .ques {
    .did {
      display: inline-block;
      width: 64px;
      height: 22px;
      background: url('../../../images/did.png') no-repeat 0 0;
      padding-left: 30px;
      font-size: @font-base;
      color: #EDB35B;
    }
    .undid {
      display: inline-block;
      width: 80px;
      height: 22px;
      font-size: @font-base;
      color: red;
      .close {
        width: 22px;
        height: 22px;
        background: red;
        color: #fff;
        display: inline-block;
        text-align: center;
        border-radius: 50%;
        margin-right: 10px;
      }
    }

    & > p {
      font-weight: bold;
      font-size: @font-middle;
      margin-bottom: @margin-sm;
    }

    & > div {
      padding-left: 12px;
      display: flex;
      flex-direction: column;
      color: @text-color-secondary;

      & > div {
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
          .dot {
            border-color: #EDB35B;
          }
        }

        & > span {
          display: inline-block;
          margin-bottom: @margin-sm;

          &.dot {
            border: 1px solid @text-color-secondary;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            position: relative;
            margin-right: @margin-sm;
            &.active {
              border-color: #EDB35B;
              animation: jelly 0.9s both;
              &:after {
                content: '';
                width: 8px;
                height: 8px;
                position: absolute;
                border-radius: 50%;
                background: #EDB35B;
                top: 50%;
                left: 50%;
                margin-top: -4px;
                margin-left: -4px;
              }
            }
          }
        }
      }
    }
  }

  .rpq-result {
    & > p {
      margin-bottom: @margin-lg;
    }

    & > p > span:first-child {
      color: @text-color-secondary;
      font-size: @font-middle;
    }

    & > p > span:last-child {
      color: #EBB25A;
      font-size: @font-large;
      font-weight: 600;
    }
  }
}
