.editPassword {
  .mt20 {
    margin-top: 20px;
  }
  .container {
    padding: 43px 56px;
    background: #fff;
  }
  .title {
    font-size: 24px;
    font-weight: 500;
    color: #474747;
  }
  .form-item {
    display: flex;
    justify-content: flex-start;
    .form-item-input {
      height: 40px;
      padding-top: 0;
      padding-bottom: 0;
      width: 210px;
    }
  }

  .desc {
    display: inline-block;
    padding-bottom: 25px;
    width: 150px;
    text-align: right;
    margin-right: 30px;
  }

  .code {
    padding: 11px 13px;
    background: #DEDEDE;
    color: #868686;
    margin-left: 15px;
    margin-bottom: 19px;
    cursor: pointer;
    height: 38px;
    line-height: 16px;
  }
  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flex6 {
    flex: 6;
  }
  .submitBtn {
    width: 100%;
    text-align: center;
    margin-top: 50px;

    .submit {
      height:40px;
      background: #8D6F4A;
      padding: 0 75px;
      color: #fff;
      border-radius: 10px;
      text-align: center;
      cursor: pointer;
    }
  }
}

