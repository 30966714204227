@import (reference) '../../theme.less';

.upload-file {
  h1{
    color:@text-color;
    font-size:@font-large;
    font-weight:600;  
  }
  ul{
    display: flex;
    justify-content: flex-start;
    margin-top: @margin-md;
    li{
      width:200px;
      height:200px;
      position: relative;
    margin-right: @margin-md;

      background: #F7F8FC;
      text-align: center;
      line-height: 200px;
      .dynamic{
          width:100%;
          height:100%;
      }
      .mask{
        width:100%;
        height:100%;
        position: absolute;
        top:0;
        left:0;
        background: rgba(0,0,0,0.4);
        display: none;
        img{
              position: absolute;
              top:0;
              left:0;
              width:32px;
              height:32px;
              cursor: pointer;
        }
      }
        &.delete:hover{
          .mask{
            display: block;
          }
        }
    }
  }
}
