@import (reference) '../../../theme.less';

.my-product {
  .top {
    height: 300px;
    background: #F7F8FC;
    display: flex;

    .overview {
      height: 100%;
      flex: 1;
      padding: 30px 25px;
      background: @white-color;

      .f {
        display: flex;

        .t {
          flex: 2;
          font-weight: 600;

          & > p:first-child {
            font-size: @font-middle;
            margin-bottom: @margin-md;
          }

          & > p:last-child {
            font-size: @font-large + 8;
          }
        }

        .o {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;

          img {
            width: 34px;
            height: 24px;
            margin-bottom: @margin-sm;
          }

          & > span {
            color: #F7DA49;
          }
        }
      }

      .l {
        margin-top: @margin-lg + 15;
        display: flex;
        font-size: @font-middle;

        & > div {
          margin-right: @margin-lg;

        }

        & > div > span:first-child {
          color: @text-color-secondary;
          margin-right: @margin-sm;
        }

        & > div > span:last-child {
          color: @text-color;
          font-weight: 600;
          margin-right: @margin-sm;
        }
      }

      & > p {
        margin-top: 65px;
        color: @primary-color;
        font-size: @font-base;
      }
    }

    .chart-wrap {
      margin-left: 10px;
      height: 100%;
      width: 350px;
      background: @white-color;
      padding: 30px 20px;

      & > span {
        font-size: @font-middle;
      }

      .chart {
        width: 310px;
        height: 240px;
        position: relative;
        margin: 0 auto;
      }

    }
  }

  .pro-bottom {
    margin-top: @margin-sm;
    background: @white-color;

    .type {
      display: flex;
      margin-bottom: @margin-md;
      padding-top: 20px;

      li {
        width: 130px;
        font-size: @font-large;
        height: 26px;
        line-height: 26px;
        border-right: 1px solid @border-color-base;
        text-align: center;
        cursor: pointer;

        &.active {
          color: @primary-color;
        }

        &:last-child {
          border-right: 0;
        }
      }
    }

    .list {
      li {
        padding: 20px 25px;
        background: @white-color;

        & > h1 {
          font-size: @font-middle;
          font-weight: 600;
          margin-bottom: @margin-md;
          color: @text-color;

          span {
            border: 1px solid #ECB663;
            padding: 2px 4px;
            color: #ECB663;
            font-size: @font-base;
            margin-left: @margin-sm;
          }

        }

        & > div {
          display: flex;
          justify-content: space-between;
          flex: 1;

          & > div {
            & > p {
              font-weight: 600;
              font-size: @font-middle;

              &:first-child {
                color: @text-color;
                margin-bottom: @margin-md;

              }

              &:last-child {
                color: @text-color-secondary;
              }

              &.profit {
                color: #ECB663;
              }
            }
          }

          .btn {
            border: none;
          }
        }
      }
    }

    .pagination {
      width: 65%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 48px 0;
      background: #fff;
      margin: 15px auto 51px;
    }
  }
}
