@import (reference) '../../theme.less';

.news {
  .banner {
    width: 100%;
    position: relative;

    .w100 {
      width: 100%;
    }

    .news-title {
      position: absolute;
      top: 12.5%;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  .news-box {

    .news-list {
      cursor: pointer;
      height: 180px;
      margin: 50px 0;

      .month-row {
        margin-top: 20px;
        display: flex;
        align-items: center;
        flex-direction: row;

        .month-box {
          font-size: 40px;
          text-align: center;
          flex: 4;
          color: #333;
        }

        .month-title {
          flex: 14;
          font-size: 18px;
          position: relative;

          .border {
            top: 25px;
            left: 5px;
            position: absolute;
            height: 3px;
            width: 25px;
            border-radius: @border-radius-base;
            background: @primary-color-dark;
          }
        }
      }

      .day-row {
        display: flex;
        align-items: center;
        flex-direction: row;

        .day {
          color: #333;
          font-size: 40px;
          text-align: center;
          flex: 4;
        }

        .news-list-summary {
          flex: 14;
          margin-top: 10px;
          color: #333;
        }
      }

      .news-list-img-box {
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        .news-list-img {
          width: 80%;
          height: 150px;
        }
      }


      &:hover {
        background: url("../../images/listbg.png") no-repeat center;
        box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.35);
        color: #ffffff;

        .day {
          color: #ffffff;
        }

        .month-box {
          color: #ffffff;
        }

        .news-list-text {
          color: #ffffff;

        }

        .news-list-summary {
          color: #ffffff;
        }
      }


    }

    .news-first {
      background: url("../../images/listbg.png") no-repeat center;
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.35);
      color: #ffffff;

      .day {
        color: #ffffff !important;
      }

      .month-box {
        color: #ffffff !important;
      }

      .news-list-text {
        color: #ffffff !important;

      }

      .news-list-summary {
        color: #ffffff;
      }
    }
  }


  .news-trends {
    width: @page-width;
    margin: auto;
    margin-top: @margin-lg;

    .title {
      margin-bottom: @margin-lg;
    }

    .news-type {
      display: flex;
      justify-content: space-around;

      span {
        border: 1px solid @border-color-base;
        height: 70px;
        width: 200px;
        line-height: 70px;
        text-align: center;
        font-size: @font-base + 2;
        font-weight: 500;
        cursor: pointer;


        &.active {
          color: @primary-color;
        }

        &:hover {
          color: @primary-color;
        }
      }

      .active-plate {
        border: 1px solid @primary-color-dark;
        color: @primary-color
      }

    }


    /******************************/


    /****************************/

  }

}

.pagination {
  width: 65%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 48px 0;
  background: #fff;
  margin: 15px auto 51px;
}
